export const formatCurrencyIDR = (number: number): string => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  export const formatPrice = (number: number): string => {
    if (number >= 1000) {
        if (number >= 1000000) {
            return "Rp."+`${(number / 1000000).toFixed(0)}jt`;
        }
        return "Rp."+`${(number / 1000).toFixed(0)}K`; // Changes 450.000 to 450K
      }
      return "Rp."+ number.toString();
  };

  export const formatDate = (number: string): string => {
  if(number == 'MONTHLY'){
    return "month"
  }
  return "year"
  };

//   function (value) {
//     // Converts the number to a simplified format

//   }
